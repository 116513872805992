import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Tab from './Tab';
import './styles.css';
class Tabs extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props);
    // console.log('props in tabs');
    // console.log(this.props);
    this.state = {
      activeTab: window.location.pathname,
    };
  }

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  }

  render() {
    // console.log('Props in Tabs.js are : ');
    // console.log(this.props);
    const {
      onClickTabItem,
      props: {
        children,
      },
      state: {
        activeTab,
      }
    } = this;

    return (
      <div className="tabs">
        <ul className="tab-list">
          {children.map((child) => {
            const { label, path } = child.props;

            return (
              <Tab
                activeTab={activeTab}
                key={label}
                label={label}
                path={path}
                onClick={onClickTabItem}
              />
            );
          })}
        </ul>
        <div className="tab-content">
          {children.map((child) => {
            if (child.props.path !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}

export default Tabs;