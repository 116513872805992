import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/shared/header/header';
import Footer from './components/shared/footer/footer';
import Home from './components/Home/home';
import Privacy from './components/shared/privacy/privacy';
import GarbageMap from './components/GarbageMap/GarbageMap';
import Amplify, { Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
//import { withAuthenticator } from 'aws-amplify-react';
Amplify.configure(awsconfig);
class App extends Component {
  renderView() {
    let pathname = window.location.pathname;
    if(pathname.startsWith('/mobileview')) {
      return (
        <Router>
        <div className="App">
            <Switch>
                <Route path="/mobileview/garbage" component={GarbageMap}>
                  {/* <div className="App-content">
                      <GarbageMap />
                      </div> */}
                  </Route>
                <Route path="/mobileview" component={Home}>
                </Route>
              
            </Switch>
            
        </div>
      </Router>
      );
    } else {
      return (
        <Router>
          <div className="App">
            <Header/>
            
              <Switch>
                  <Route path="/garbage" component={GarbageMap}/>
                  <Route path="/privacy" component={Privacy}/>
                  <Route path="/" component={Home}/>
              </Switch>
              <Footer/>
          </div>
        </Router>
      );
    }
  }
  render() {
    console.log('Props in app are : ');
    console.log(window.location.pathname);
    return (
     <div>{this.renderView()}</div>
    );
    
  }
}



export default App;
// export default withAuthenticator(App, false);