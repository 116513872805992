import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';

class GoogleMaps extends Component {
    constructor(props) {
        super(props);
        console.log('Props in google Maps');
    console.log(this.props.objToMark);
        // this.state = {
        //   stores: [{lat: 47.49855629475769, lng: -122.14184416996333},
        //           {latitude: 47.359423, longitude: -122.021071},
        //           {latitude: 47.2052192687988, longitude: -121.988426208496},
        //           {latitude: 47.6307081, longitude: -122.1434325},
        //           {latitude: 47.3084488, longitude: -122.2140121},
        //           {latitude: 47.5524695, longitude: -122.0425407}]
        // }
        this.state = {
          stores: [],
          updateCount: this.props.updateCount
        }
      }

      displayMarkers = () => {
        return this.props.objToMark.stores.map((store, index) => {
          return <Marker key={index} id={index} position={{
           lat: store.latitude,
           lng: store.longitude
         }}
         onClick={() => console.log("You clicked me!")} />
        })
      }

      render() {
        return (
            <Map
              google={this.props.google}
              zoom={8}
              style={mapStyles}
              initialCenter={{ lat: this.props.objToMark.centrePoint.lat, lng: this.props.objToMark.centrePoint.lng}}
            >
              {this.displayMarkers()}
            </Map>
        );
      }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyCcG2AINlmLNfQEqa7uWoHNmhnRW3tRl_8'
  })(GoogleMaps);
//export default GoogleMaps;
const mapStyles = {
    width: '100%',
    height: '100%',
  };