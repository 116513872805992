import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import './styles.css';
class Tab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  };

  onClick = () => {
    const { label, onClick, path } = this.props;
    onClick(path);
  }

  render() {
    const {
        onClick, 
      props: {
        activeTab,
        label,
        path
      },
    } = this;
    // console.log('this.props is ');
    // console.log(this.props);

    let className = 'tab-list-item';

    if (activeTab === path) {
      className += ' tab-list-active';
    }

    return (
      <li 
        className={className}
        onClick={onClick}
      >
        <Link to={path} className="test">{label}</Link>
      </li>
    );
  }
}


export default Tab;