import React, { Component } from 'react';
import './footer.css';
export default class Footer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className='footer-basic-centered'>
            <p className='footer-company-motto'>Reflecting the Society as is.</p>
            <p className="footer-links">
				<a href="https://mirror2society.com/">Home</a>
				  &nbsp;· &nbsp;
				<a href="https://mirror2society.com/garbage">Garbage Map</a>
				&nbsp; · &nbsp;
				<a href="#">Privacy Policy</a>
                &nbsp; · &nbsp;
				<a href="#">About</a>
				&nbsp; · &nbsp;
				<a href="#">Faq</a>
				&nbsp; · &nbsp;
				<a href="#">Contact</a>
			</p>
            <p className="footer-company-name">Mirror2Society © 2020</p>
            </div>
        );
    }
}