import React, { Component } from 'react';
import FlatList from 'react';
import GoogleMaps from '../shared/GoogleMaps/GoogleMaps';
import GoogleMap from '../shared/GoogleMapReact/GoogleMap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { geolocated } from "react-geolocated";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import { Link,  } from "react-router-dom";
import { getCurrentPosition, 
        reverseGeocodeAPI,
        getCityFromGeocodeJson 
      } from '../Utils/Utils';
import './loader.css';

class GarbageMap extends Component {
    constructor(props) {
        super(props);
        // console.log('Props available in Garbage Map are : ');
        // console.log(this.props);
        this.state = {
          location: {
            lat:0,
            long:0
          },
          dataToGMaps:{},
          dataToSend: {
            stores: [],
            centrePoint: {}
          },
          center:{
            lat:52.2254981,
            lng:20.9390872,
          },
          updateCount: 0,
          isLoading: true,
          city: ''
        }
      } 
      
      abortController = new AbortController();

      async componentDidMount() {
        // console.log('Inside Mount Function');
        // if(this.props.coords) {
        //   this.state.location = this.props.coords;
        // }
        let newItem = {};
        if(this.props.location.search) {
          // console.log('Inside If of search Params');
          let filter_search_string = (this.props.location.search).split('?');
          // console.log(filter_search_string[1]);
          let latLongArr = (filter_search_string[1]).split('&');
          let lat = (latLongArr[0].split('='))[1];
          let long = (latLongArr[1].split('='))[1];
          newItem = {
            lat:parseFloat(lat),
            lng:parseFloat(long),
            search_radius_meters:50000
          }
          this.setState({
            center: {
              lat: parseFloat(lat),
              lng:parseFloat(long)
            }
          }
            
          )
        }else if(navigator.geolocation) {
            // navigator.geolocation.getCurrentPosition(function(position) {
            //   console.log('Position is : ');
            //   console.log(position);
            // });
            let position = await getCurrentPosition();
            // console.log(position);
            newItem = {
              lat:position.coords.latitude,
              lng:position.coords.longitude,
              search_radius_meters:50000
            }
            this.setState({
              center: {
                lat: position.coords.latitude,
                lng:position.coords.longitude
              }
            }
              
            )
        }
        else {
          // console.log('Props is empty');
          // this.state.location.latitude = 12.9139539;
          // this.state.location.longitude = 77.6701942;
          newItem = {
            lat:12.9139539,
            lng:77.6701942,
            search_radius_meters:50000
          }
        }
        
        
        // console.log(JSON.stringify(newItem));
        
        await this.fetchGarbageLocationsNearBy(newItem);
         
        
          
          //let response = JSON.parse(fetchDataJson[0].geosJson.S);
          //console.log(response.coordinates);  
          // .then((response) => response.json())
          // .then((responseJson) => {
          // console.log(responseJson);
          // })
          // .catch((error) => {
          // console.error(error);
          // });
      }

      // componentWillUnmount() {
      //   this.abortController.abort();
      // }

      async fetchGarbageLocationsNearBy(newItem) {
        let fetchData = await fetch(FETCH_DETAILS_URL, {
          method: 'POST',
          headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          },
          body: JSON.stringify(newItem)});
          let fetchDataJson = await fetchData.json();
          // console.log('Fetched Data is ');
          // console.log(JSON.stringify(fetchDataJson));

          let dataToGMapsArr = [];
          for (const locItem of fetchDataJson) {
            //console.log(locItem);
            let tempLocObject = {};
            let  tempLocObjJSON= JSON.parse(locItem.geoJson.S);
            let latitude = tempLocObjJSON.coordinates[1];
            let longitude = tempLocObjJSON.coordinates[0];
            tempLocObject.username = locItem.username.S;
            tempLocObject.s3filelocation = locItem.s3filelocation.S;
            tempLocObject.timestamp = locItem.timestamp.S;
            tempLocObject.latitude = latitude;
            tempLocObject.longitude = longitude;
            dataToGMapsArr.push(tempLocObject);
          }

          let geocode = await reverseGeocodeAPI(newItem.lat, newItem.lng);
            
            let city = await getCityFromGeocodeJson(geocode);
            // console.log('City is ');
            // console.log(JSON.stringify(city));
          // console.log('Data to send');
          // console.log(JSON.stringify(dataToGMapsArr));
          this.setState(
            {
              dataToSend: {
                stores:dataToGMapsArr,
                centrePoint: {
                  lat:this.state.location.latitude,
                  lng:this.state.location.longitude
                },
                city: city,
              },
              isLoading: false,
              city: city
              
          });
          //this.setState({dataToSend: {stores:dataToGMapsArr}});
          
          // this.state.dataToSend = {stores:dataToGMapsArr};
          // let count = this.state.updateCount;
          // count = count +1;
          //this.setState({updateCount: count});
          //this.state.updateCount = this.state.updateCount + 1;
          // console.log('this.state.dataToMaps');
          // console.log(this.state.dataToSend);
      }

      renderLeft() {
        // console.log('Render Left');
        // console.log(window.location);
        // <a href="http://www.example.com">My sample page </a>
        if(!window.location.pathname.startsWith('/mobileview')) {
          return(
            <Col sm={2}>
            <div className='left-bar-header'> Pilot Cities</div>
            {/* <Link to={`/garbage?lat=12.93&long=77.696`}>Bengaluru, Karnataka, India</Link> */}
            <div>
            <a href={window.location.origin+'/garbage?lat=12.93&long=77.696'}>Bengaluru, Karnataka, India</a>
            </div>
            </Col>
          );
        } else {
          return null;
        }
        
      }

      render() {
        // console.log('this.state.dataToSend.stores.length ');
        // console.log(this.state.dataToSend.stores.length);
        if(this.state.isLoading) {
              
          return(
            //<Container>
            <Row>
              {this.renderLeft()}
              <Col sm={10} className="loader">
              <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            timeout={3000} //3 secs
   
         />
              </Col>
            </Row>
            
          //</Container>
          )
        
      } else if(this.state.dataToSend.stores.length > 0) {
          return (
            //<Container>
            <Row>
              {this.renderLeft()}
              <Col sm={8} style={mapStyles}>
                <div className='city'> {this.state.city}</div>
                <div className='city-info'> Click the icons in the map to see the images!!</div>
                <GoogleMap objToMark={this.state.dataToSend} center={this.state.center}></GoogleMap>
              </Col>
            </Row>
            
          //</Container>
               
            
          );
        }
        else {
          return(
            //<Container>
            <Row >
              {this.renderLeft()}
              <Col sm={8} style={mapStyles}>
                <div className='city'> {this.state.city}</div>
                <div className='city-info'> Click the icons in the map to see the images!!</div>
                <GoogleMap objToMark={this.state.dataToSend} center={this.state.center}></GoogleMap>
              </Col>
            </Row>
            
          //</Container>
          );
        }
          
      }
}

export default GarbageMap;
// export default geolocated({
//     positionOptions: {
//         enableHighAccuracy: false,
//     },
//     userDecisionTimeout: 5000,
// })(GarbageMap);
const mapStyles = {
    width: '100%',
    height: '100vh',
  };

  const FETCH_DETAILS_URL = "https://qvp9wjswyg.execute-api.ap-south-1.amazonaws.com/dev/search";