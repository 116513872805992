import React, { Component } from 'react';
import Loader from 'react-loader-spinner'
import image from './garbage-icon-12.png';
import { Storage, Auth, API } from 'aws-amplify';
import ModalImage from "react-modal-image";
class RenderImageFromS3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading:true,
            imageUrl:null
          }
          console.log('Item passed as Icon is :');
          console.log(JSON.stringify(this.props.details));
      }

      async componentDidMount() {
        await this.getImageFromS3Storage(this.props.details);
      }

      arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    };

      getImageFromS3Storage(filepath) {
        Storage.get(filepath)
        .then((result) => {
            console.log("**********************************");
            console.log(JSON.stringify(result));
            //this.state.imageUrl = JSON.stringify(result);
            this.setState({ imageUrl: result });
            console.log("**********************************");
        })
        .catch(err => console.log(err));
      }

      render() {
         // console.log(this.state.imageUrl);
          return(
            <img
                    className="image"
                    // src="https://m2smediabucket-dev-dev.s3.ap-south-1.amazonaws.com/public/anshulkarasi009-1570481318542.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAWKD7YBWRYWSSTJHV%2F20191013%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20191013T185051Z&X-Amz-Expires=900&X-Amz-Security-Token=AgoJb3JpZ2luX2VjECMaCmFwLXNvdXRoLTEiSDBGAiEAsMd53In3U3iLKH3ujMkmCNAM2qAdjmWlcRiJF4JwZaYCIQCqiOyy1bTvdhQuaiqbq07Oo21OkAOlARcUUZ%2BH9aPN%2ByqsBQgcEAEaDDQzNDA1OTYxMTU1NSIM75sdOrOOVUl7uqcaKokFzBjHF%2BvWj9g5164EvzzScqPmVZ9NOSnEU0KUVU4aoH5zf21CXHd685Xn0FQAqn7G4nZo5UkLiWQLnLVs4eCaDZdkaukegUgYKLCWN6AovF5Ios6GkWBpRnT3chgv0%2FcDpga2rzF2O5KCpSt42ch3F0hVQLw4BNT0ZToFtcjAhbwsqwcK9FoO4EL%2Bm67U%2BWuedntdTE9JgyoYb3i0bYbe0OghjtTjM4SIMqUBX15visAEVokcQJ6HXNCXBgDdvXUAVzGwLkltdiilUQ8TplYre1gwvKGkCNAR4jAJtgsF5zFvgfxZLtu%2BfHR7aJT9CWHdxzGM5JkmxPhi6jJzhdPBZffKtu8soBBvoQ3kPMv66jUn%2BPUxfTY15enmuuDCX6fnX5rqRvjNRVYXz2GUVkTrWjKg9Sgi6GQXhkQL41gb1j8XB%2BULDcUuKeZsbsr3HASRxj0C5a0uxjNr5N6XBuo8t3rLxgD2I3Nn%2FIvPdNQsY%2FjsPQFNf40%2FKmMHjU0xyoFtnP7zsYOXQKfVYdywyXZ6FYfE4rPaQKYfPzRyk%2B4aydf2QgXzyj2prhNGUaeNQssGDleiMNtQ1mFchCDq9GEgMNRQGHVveFGk2JT2uR2YBbWfHfCVYvTOvpNTI%2BbfXUDIPEU36MdTAGdyl9BQQHFXgnOjF2g4AU01jrjkTRn5K5yXtWFGhr%2BThMuEs%2Ffn58SpTF3JIHaF4VbtnRiAO0a43kGARVGvAKCsvNuY4DiZ%2FsfYOCrCZmXES9b4Z9hwcW2jCnKvN3rNpRuFTKeZDvtL0FguX9OV8hkQrMVRhmHwUMnZWU5xYojAHweUm1vGeHQfeTcjxn%2BpsMYSLu8QXmc00ACwDA4HEQ4%2BzjCX5I3tBTqzATzgjuyhPJALhFMMmgIAREF76K9VH%2BW1kTPGWg69OID2%2FaY5kpQkPiNGSDJ3A%2BS4ihvYxGKTVWubYbGQ%2FIF%2FRoS8pNNChnuLRohy7GXUmLcVLDk9b0VFM760L52LGr1k1fTalyQAMUKolpdXfTRhLBig2pzW41FkA%2BubKn8UGd%2B5SC9TFPAlmt3PH%2FDZTH8prIVATTTjFi%2FlhQPR1LXaWfS%2BqUdM4YEXJcsMS2sIA3iGYaUl&X-Amz-Signature=700f19b1606ef29455f07c17ccb34baa44f6530c2a61fa988b6a9bca0f9c5c11&X-Amz-SignedHeaders=host"
                    src={this.state.imageUrl}
                    alt="no image"
                />
          );
    }
}

export default RenderImageFromS3;