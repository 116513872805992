// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:30a95c33-6ec6-4c73-9f91-6eadfb81f774",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_JL3MBHaEt",
    "aws_user_pools_web_client_id": "1q995sg6nljcnqst32u962q0ih",
    "oauth": {
        "domain": "mirror2society942a4ec0-942a4ec0-dev.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "m2smediabucket-dev-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1",
    "aws_dynamodb_all_tables_region": "ap-south-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "m2smediatable",
            "region": "ap-south-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "storagem2s",
            "endpoint": "https://upq53ebqi3.execute-api.ap-south-1.amazonaws.com/Prod",
            "region": "ap-south-1"
        }
    ],
    "Analytics": {
        "disabled": true
    }
};


export default awsmobile;
