import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logo from './mirror2society_logo.png';
import Tabs from '../Navigation/Tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './header.css';
class Header extends Component {
    constructor(props) {
        super(props);
      }

      render() {
          return (
            <Row className="App-header">
            <Col sm={2}><img src={logo} className="App-logo" alt="logo" /></Col>
                <Col sm={10} className="tab-header">
                <Tabs>
                    <div label="Home" path="/">
                    </div>
                    <div label="GarbageMap" path="/garbage">
                    </div>
                </Tabs>
                </Col>
            </Row>
          );
      }
}

export default Header;