import React, { Component } from 'react';
import icon from './garbage-icon-12.png';
import './garbage-icon.scss';
import Loader from 'react-loader-spinner';
import { Storage, Auth, API } from 'aws-amplify';
import RenderImageFromS3 from '../RenderImageFromS3/RenderImageFromS3';
const IMAGE_FILE_URL = 'https://m2smediabucket-dev-dev.s3.ap-south-1.amazonaws.com/public/';
class GarbageIcon extends Component {
    constructor(props) {
        super(props);
        // console.log('Item passed as Icon1 is :');
        //   console.log(JSON.stringify(this.props.itemDetails));
          this.state = {
            imageDetails: this.props.itemDetails,
            isOpen: false,
            isImageLoading: false,
            img:null
          }
      }
      async arrayBufferToBase64(buffer) {
        var binary = '';
        var bytes = [].slice.call(new Uint8Array(buffer));
        bytes.forEach((b) => binary += String.fromCharCode(b));
        return window.btoa(binary);
    };

    //   async getImageFromS3Storage(filepath) {
    //     Storage.get(filepath)
    //     .then((result) => {
    //         console("**********************************");
    //         console.log(result);
    //         console("**********************************");
    //     })
    //     .catch(err => console.log(err));
    //   }
    
      renderImage(s3Location) {
          console.log('Rendering');
            return (
                <RenderImageFromS3 details={s3Location} onClick={this.handleShowDialog}></RenderImageFromS3>
              );
      }

    //   render() {
    //       return (
    //         //<Hover onHover={()=>this.renderImage()}>
    //         <img src={icon} className="garbage-icon" alt="icon" />
    //         //</Hover>
            
    //       );
    //   }

    async fetchImage(s3Location) {
    //let imageUrlData = await fetch(IMAGE_FILE_URL + s3Location);
    let imageUrlData = await fetch('https://m2smediabucket-dev-dev.s3.ap-south-1.amazonaws.com/public/anshulkarasi0091570448895911.jpeg',{
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'no-cors', // no-cors, *cors, same-origin
        // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        // credentials: 'same-origin', // include, *same-origin, omit
        // headers: {
        //   'Content-Type': 'application/json'
        //   // 'Content-Type': 'application/x-www-form-urlencoded',
        // }
    }
    
);
    //let imageUrlDataJSON = await imageUrlData.json();
    console.log('Image File Data is ');
    console.log(imageUrlData);
    console.log('End');
    var base64Flag = 'data:image/jpeg;base64,';
        var imageStr = await this.arrayBufferToBase64(imageUrlData);
        // this.setState({
        //     img: base64Flag + imageStr
        // )}
        this.state.img = base64Flag + imageStr;
        this.setState({ isImageLoading: !this.state.isImageLoading });
        this.setState({ isOpen: !this.state.isOpen });
    // async function postData(url = '', data = {}) {
    //     // Default options are marked with *
    //     const response = await fetch(url, {
    //       method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //       mode: 'cors', // no-cors, *cors, same-origin
    //       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //       credentials: 'same-origin', // include, *same-origin, omit
    //       headers: {
    //         'Content-Type': 'application/json'
    //         // 'Content-Type': 'application/x-www-form-urlencoded',
    //       },
    //       redirect: 'follow', // manual, *follow, error
    //       referrer: 'no-referrer', // no-referrer, *client
    //       body: JSON.stringify(data) // body data type must match "Content-Type" header
    //     });
        

    //     fetch('http://yourserver.com/api/img_data')
    // .then((res) => res.json())
    // .then((data) => {
    //     // console.log(img)
    //     var base64Flag = 'data:image/jpeg;base64,';
    //     var imageStr = this.arrayBufferToBase64(data.img.data.data);
    //     this.setState({
    //         img: base64Flag + imageStr
    //     )};
    // })
    }
    handleShowDialog = (s3Location) => {
        this.setState({ isOpen: !this.state.isOpen });
        // console.log('S3 location of the file is : ');    
        // console.log(s3Location);
        //this.setState({ isImageLoading: !this.state.isImageLoading });
        // if(typeof s3Location === 'string'){
            
        //     console.log('True');
        //     this.fetchImage(s3Location);
        // }
        
        console.log("File name : " + s3Location);
        //this.renderImage(s3Location);
      };

    render() {

        return (
          <div>
            <img
              className="garbage-icon"
              src={icon}
              onClick={(item)=>{this.handleShowDialog(this.props.itemDetails.s3filelocation)}}
              alt="no image"
            />
            {this.state.isOpen && (
              <dialog
                className="dialog"
                style={{ position: "absolute" }}
                open
                onClick={this.handleShowDialog}
              >
                {/* <img
                  className="image"
                  src={
                      'https://m2smediabucket-dev-dev.s3.ap-south-1.amazonaws.com/public/'+
                      this.props.itemDetails.username + '-' +
                      this.props.itemDetails.s3filelocation 
                    }
                  onClick={this.handleShowDialog}
                  alt="no image"
                /> */}
                {this.renderImage(this.props.itemDetails.username + '-' +
                      this.props.itemDetails.s3filelocation )}
              </dialog>
            )}
            {/* {this.state.isImageLoading && (
              <dialog
                className="dialog"
                style={{ position: "absolute" }}
                open
                onClick={this.handleShowDialog}
              >
                <Loader
                type="Puff"
                color="#00BFFF"
                height={100}
                width={100}
                timeout={30000} //3 secs
       
             />
              </dialog>
            )} */}
          </div>
        );
      }
}


const Hover = ({ onHover, children }) => (
    <div className="hover">
        <div className="hover__no-hover">{children}</div>
        <div className="hover__hover">{onHover}</div>
    </div>
);

export default GarbageIcon;