const MAP_API_KEY = "AIzaSyCcG2AINlmLNfQEqa7uWoHNmhnRW3tRl_8";
const MAP_API_URL = "https://maps.googleapis.com/maps/api/geocode/json"
export function getCurrentPosition(options = {}) {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
}

export async function reverseGeocodeAPI(lat, long) {
 let gecodeJson = await fetch(MAP_API_URL+'?latlng=' + lat + ','+long+'&key=' + MAP_API_KEY);
 return await gecodeJson.json();
}

export async function getCityFromGeocodeJson(geocodeJson) {
    // console.log('Geocode Inside function ');
    // console.log(geocodeJson);
    //if (geocodeJson.status == 'ZERO_RESULTS');
    let city = 'Unknown Location to Google Maps';
    if(geocodeJson.status === 'OK') {
        let compoundCode = geocodeJson.plus_code.compound_code;
        //console.log('Compound code : ' + compoundCode);
        if(compoundCode) {
            let compund_code_arr = compoundCode.split(',');
            city = (compund_code_arr[0].split(' '))[1];
            //console.log(city);
            for(var i=1;i<compund_code_arr.length;i++) {
                city = city + ',' +compund_code_arr[i];
            }
        } else {
            city = geocodeJson.results[0].formatted_address;
        }
    }
    return city;
}