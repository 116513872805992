import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import GarbageIcon from '../GarbageIcon/GarbageIcon';
const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMap extends Component {
    static defaultProps = {
        center: {
          lat: 52.2254981,
          lng: 20.9390872
        },
        zoom: 13
      };
    constructor(props) {
        super(props);
        
      }

      // displayMarkers = () => {
      //   return this.props.objToMark.stores.map((store, index) => {
      //     return <Marker key={index} id={index} position={{
      //      lat: store.latitude,
      //      lng: store.longitude
      //    }}
      //    onClick={() => console.log("You clicked me!")} />
      //   })
      // }

      displayMarkers = () => {
        return this.props.objToMark.stores.map((store, index) => {
          return <GarbageIcon
          key={index}
          itemDetails={store}
          lat={store.latitude}
          lng={store.longitude}
          text="My Marker"
        />
        })
      }

      render() {
        console.log('Maps props');
        console.log(this.props);
        return (
          // Important! Always set the container height explicitly
          <div style={{ height: '80vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: 'AIzaSyCcG2AINlmLNfQEqa7uWoHNmhnRW3tRl_8' }}
              // defaultCenter={this.props.center}
              center={this.props.center}
              defaultZoom={this.props.zoom}
            >
              {this.displayMarkers()}
            </GoogleMapReact>
          </div>
        );
      }
}

export default GoogleMap;