import React, { Component } from 'react';
import GoogleMap from '../shared/GoogleMapReact/GoogleMap';
import './home.css';
import map_image from './map.png';
class Home extends Component {
    constructor(props) {
        super(props);
      }

      render() {
          return (
           
        
    <div className="w3-light-grey">

        <div className="w3-content w3-width">

    <header className="w3-container w3-center w3-padding-32"> 
    <h1><b>Mirror2Society</b></h1>
    <p>A Platform which reflects the <span className="w3-tag">Society</span> as it is.</p>
    </header>


    <div className="w3-row">

{/* <!-- Blog entries --> */}
<div className="w3-col l8 s12">
  {/* <!-- Blog entry --> */}
  <div className="w3-card-4 w3-margin w3-white">
    <img src={map_image} alt="Nature" className="map-image"/>
    <br></br>
    <div className="w3-container">
      <h3><b>What is Mirror2Society ?</b></h3>
      {/* <h5>Title description, <span class="w3-opacity">April 7, 2014</span></h5> */}
    </div>

    <div className="w3-container">
      <p>
        <b>Mirror2Society is a platform where, Citizens can click as well as upload images of the things, which concerns us collectively as a Society, 
      (eg. Garbage Dumps, Road Conditions etc.) using our iOS/Android App.</b>
          
          <br></br>
          <br></br>
          <b>Every image is geo-tagged and can be seen in the maps of the respective section by clicking the icon.</b>
          <br></br>
          <br></br>
          <b>We are piloting with GarbageMaps where, using Artificial Intelligence (AI), only images with garbage dumps will be allowed to upload
          in GarbageMap section.</b>
          <br></br>
          <br></br>
          <b>User can share the live maps of their areas via social media and tag respective authorities. 
          Once, the authorities takes appropriate action, and click as well as upload the image, as proof of work done via our App,
           the image will be removed from the Map and notifiy the users nearby to that location. </b>
          <br></br>
          <br></br>
          <b>In Pipeline - Road Condition Map, Helping NGO's dealing with supply of Excess Food to the ones in need.</b>
      </p>
      {/* <div class="w3-row">
        <div class="w3-col m8 s12">
          <p><button class="w3-button w3-padding-large w3-white w3-border"><b>READ MORE »</b></button></p>
        </div>
        <div class="w3-col m4 w3-hide-small">
          <p><span class="w3-padding-large w3-right"><b>Comments  </b> <span class="w3-tag">0</span></span></p>
        </div>
      </div> */}
    </div>
  </div>
  <hr></hr>

  {/* <!-- Blog entry --> */}
  {/* <div className="w3-card-4 w3-margin w3-white">
  <img src="/w3images/bridge.jpg" alt="Norway" styles="width:50%"/>
    <div className="w3-container">
      <h3><b>BLOG ENTRY</b></h3>
      <h5>Title description, <span className="w3-opacity">April 2, 2014</span></h5>
    </div>

    <div className="w3-container">
      <p>Mauris neque quam, fermentum ut nisl vitae, convallis maximus nisl. Sed mattis nunc id lorem euismod placerat. Vivamus porttitor magna enim, ac accumsan tortor cursus at. Phasellus sed ultricies mi non congue ullam corper. Praesent tincidunt sed
        tellus ut rutrum. Sed vitae justo condimentum, porta lectus vitae, ultricies congue gravida diam non fringilla.</p>
      <div className="w3-row">
        <div className="w3-col m8 s12">
          <p><button className="w3-button w3-padding-large w3-white w3-border"><b>READ MORE »</b></button></p>
        </div>
        <div className="w3-col m4 w3-hide-small">
          <p><span className="w3-padding-large w3-right"><b>Comments  </b> <span className="w3-badge">2</span></span></p>
        </div>
      </div>
    </div>
  </div> */}
{/* <!-- END BLOG ENTRIES --> */}
</div>

{/* <!-- Introduction menu --> */}
<div className="w3-col l4">
  {/* <!-- About Card --> */}
  <div className="w3-card w3-margin w3-margin-top">
  {/* <img src="/w3images/avatar_g.jpg" styles="width:100%"/> */}
  <div className="w3-container w3-padding">
  <h4><b>Our Mission</b></h4>
    </div>
    <div className="w3-container w3-light-blue">
    <br></br>
      <p> <b><span className="mission-text">The first step of solving any problem is to acknowledge one.</span></b>
      <br></br>
          <br></br>
          "Our mission is to enable citizens to raise awareness about the real condition of their surroundings."
          
        
      </p>
    </div>
  </div><hr></hr>
  
  {/* <!-- Posts --> */}
  <div className="w3-card w3-margin">
    <div className="w3-container w3-padding">
      <h4>Shortcut Links</h4>
    </div>
    <ul className="w3-ul w3-hoverable w3-white">
      <li className="w3-padding-16">
        {/* <img src="/w3images/workshop.jpg" alt="Image" className="w3-left w3-margin-right" styles="width:50px"/> */}
        <span className="w3-large">GarbageMap</span><br></br>
        <a href={window.location.origin+'/garbage'}>Click to see the GarbageMap of your area</a>
      </li>
      <li className="w3-padding-16">
        <span className="w3-large">GarbageMap - Bengaluru, India</span><br></br>
        <a href={window.location.origin+'/garbage?lat=12.93&long=77.696'}>Click to see the GarbageMap of Bengaluru</a>
      </li>
      <li className="w3-padding-16">
        <span className="w3-large">GarbageMap - Dehradun, India</span><br></br>
        <a href={window.location.origin+'/garbage?lat=30.28&long=78.04'}>Click to see the GarbageMap of Dehradun</a>
      </li> 
      <li className="w3-padding-16">
        <span className="w3-large">Download App</span><br></br>
        <span>Coming Soon</span>
      </li>
      <li className="w3-padding-16">
        <span className="w3-large">More Features</span><br></br>
        <span>In Pipeline</span>
      </li>     
      {/* <li className="w3-padding-16 w3-hide-medium w3-hide-small">
        <img src="/w3images/rock.jpg" alt="Image" className="w3-left w3-margin-right" styles="width:50px"/>
        <span className="w3-large">Mingsum</span><br></br>
        <span>Lorem ipsum dipsum</span>
      </li>   */}
    </ul>
  </div>
  <hr></hr> 
 
  {/* <!-- Labels / tags --> */}
  {/* <div className="w3-card w3-margin">
    <div className="w3-container w3-padding">
      <h4>Tags</h4>
    </div>
    <div className="w3-container w3-white">
    <p><span className="w3-tag w3-black w3-margin-bottom">Travel</span> <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">New York</span> <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">London</span>
      <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">IKEA</span> <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">NORWAY</span> <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">DIY</span>
      <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">Ideas</span> <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">Baby</span> <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">Family</span>
      <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">News</span> <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">Clothing</span> <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">Shopping</span>
      <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">Sports</span> <span className="w3-tag w3-light-grey w3-small w3-margin-bottom">Games</span>
    </p>
    </div>
  </div> */}
  
{/* <!-- END Introduction Menu --> */}
</div>

{/* <!-- END GRID --> */}
</div><br></br>

{/* <!-- END w3-content --> */}
</div>

{/* <!-- Footer --> */}
{/* <footer className="w3-container w3-dark-grey w3-padding-32 w3-margin-top">
  <button className="w3-button w3-black w3-disabled w3-padding-large w3-margin-bottom">Previous</button>
  <button className="w3-button w3-black w3-padding-large w3-margin-bottom">Next »</button>
  <p>Powered by <a href="https://www.w3schools.com/w3css/default.asp" target="_blank">w3.css</a></p>
</footer> */}

</div>
// </html>

          );
      }
}

export default Home;